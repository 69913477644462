'use strict';

let	tab = document.querySelectorAll('.c-tabs__tab'),
		tabContent = document.querySelectorAll('.c-tab-content__item'),
		tabs = document.querySelector('.c-content-slider__tabs');

function showTabsContent(b) {
	hideTabsContent(0);
	tab[b].classList.add('active-item');
	tabContent[b].classList.add('active');
}

function hideTabsContent(a) {
		for (let i = a; i < tabContent.length; i++) {
			tabContent[i].classList.remove('active');
			tab[i].classList.remove('active-item');
		}
	}

if (!!tab) {
	tab.forEach(function (element) {
		element.addEventListener('click', function () {
			for (let i = 0; i < tab.length; i++) {
				if (element === tab[i]) {
					showTabsContent(i);
					break;
				}
			}
		})
	})
}
